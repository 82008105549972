import {
  BeforeSaveEventArgs,
  CellEditEventArgs,
  CellModel,
  getCell,
  getCellAddress,
  getRangeIndexes,
  SaveCompleteEventArgs,
  SpreadsheetComponent,
  getSheetName,
  getColumnHeaderText,
  CellStyleModel,
  getColumn,
  
} from "@syncfusion/ej2-react-spreadsheet";
import { classList } from "@syncfusion/ej2-base";
import { CheckOutObject, CreateObject, GetClassObjects, GetClassProperties, GetClasses, GetLatestProperties, GetObjectTypes, GetObjectVersion, GetPropertyDetails, UpdateObject, UpdateViewReport } from "./MFiles/MFilesService";
import { AdminColumnStyle, AutomaticColumnStyle, ColumnHeaderStyle, ColumnStyle, RequiredColumnStyle } from "../styles/SettingAndStyles";
import { SERVER_URL } from "../environment/env_dev";
import { ChangeEventHandler } from "react";
import { adminColData, currentRow, currentCol, transformedSheet } from "../pages/Spreadsheet";
import { compareNumbers, compareStrings, letterToNumber } from '../utils/util';
import { CheckObjectExists, GetData, IsLookupIdDefined } from "./MFiles/MFilesDto";
import { createProperty, getObjectPropertiesBL } from "./MFiles/PropertyHelper";
import { addAdminColumnHeaders, addObligatoryColumns, adjustColumnWidths, getLastEmptyRow, hideAdminColumns, IsColumnExist, markObligatoryProperties, renameSheetTab, updateCell, updateExistingObject, updateNewObject, updateSheetCells, getSheetIndex } from '../utils/spreadsheetUtil';

var modifiedRows: number[] = [];
var newRows: number[] = [];
var errorRows: any[] = [];
var issheetRepeated: boolean;
//var currentRow: any;
//var currentCol: any;
var memoryData: any[] = [];
let memoryStyleData: any[] = [];
var colStyleWidth: any[] = [];
var wrapStyle: any[] = [];
let jsonData: any;
let vaultId = sessionStorage.getItem("vaultId")!;

  export async function saveAsObject(spreadsheet: any, alertErrorDialog: any){
    errorRows = [];
    modifiedRows = [];
    newRows = [];
    let sheetName = spreadsheet?.getActiveSheet().name;
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    memoryData?.forEach(function (row, index) {
      if (row["ID"] == "-1") {
        newRows?.push(index + 1);
      } else {
        if (row["Modified"] === "True") {
          modifiedRows.push(index + 1);
        }
      }
    });
    if (newRows?.length > 0) {
      await createData(memoryData, spreadsheet, alertErrorDialog);
    }
    if (modifiedRows.length > 0) {
      await updateData(memoryData, spreadsheet, alertErrorDialog);
    }
  }

  export function createLookup(vaultId: any, propobjTypeId: any) {
    let tempvaultId = vaultId.replace("{", "").replace("}", "");    
    var newLink = "m-files://newobject/" + tempvaultId + "/" + propobjTypeId + ""
    window.open(
      newLink,
      '_blank'
    );    
  }

  
  async function createData(memoryData: any,spreadsheet: any, alertErrorDialog: any) {
    const sheetName = spreadsheet?.getActiveSheet().name;
    spreadsheet?.showSpinner();

    // Highlight the "Updated Message" column if it exists
    const updatedMessageIndex = window.ColumnHeaders.indexOf("Updated Message");
    if (updatedMessageIndex !== -1) {
        spreadsheet?.selectRange(getCellAddress(0, updatedMessageIndex));
    }

    for (const rIndex of newRows) {
        let text = '{ "PropertyValues" : [';
        const item = memoryData && memoryData[rIndex - 1];
        const clsId = item && item["Object Class"];
        const objType = item && item["Object Type"];;

        try {
            for (const item1 of window.ExistingPropIds) {
                if (item1.SheetName === sheetName) {
                    const colIndex = window.ColumnHeaders?.indexOf(item1.Name);
                    if (colIndex === -1) continue;

                    const propId = item1.Id;
                    const propDataType = item1.DataType;
                    let propName = item1.Name;
                    const cell = getCell(rIndex, colIndex, spreadsheet?.getActiveSheet()!);
                    const cellValue = spreadsheet!.getDisplayText(cell);

                    if (item1.Required && item1.AutomaticValueType == 0 && !cellValue) {
                        errorRows.push(`${item1.Name} is mandatory. Please enter a value.`);
                        continue;
                    }
                    let newProp = createProperty(propId, propName, propDataType, cellValue);
                    if (newProp) {
                        text += JSON.stringify(newProp) + ",";
                    }
                }
            }

            text = text + JSON.stringify({
                PropertyDef: 100,
                TypedValue: {
                    DataType: 9,
                    Lookup: { Item: Number(clsId) }
                }
            }) + "]";

            text += "}";
            const data = await CreateObject(objType, text);

            if (data?.Status) {
                updateCell(spreadsheet, rIndex, window.ColumnHeaders?.indexOf("Updated Message"), data.Message);
                errorRows.push("Error in record no." + rIndex + " : " + data.Message)
            } else if (data?.ObjVer?.ID > 0) {
                updateCell(spreadsheet, rIndex, window.ColumnHeaders?.indexOf("Modified"), "False" );
                updateCell(spreadsheet, rIndex, window.ColumnHeaders?.indexOf("Update Status"), "True" );
                updateCell(spreadsheet, rIndex, window.ColumnHeaders?.indexOf("GUID"), data.ObjectGUID );
                updateCell(spreadsheet, rIndex, window.ColumnHeaders?.indexOf("Updated Message"), "Added Successfully");

                reloadData(spreadsheet, data?.ObjVer?.ID, data.ObjectGUID, rIndex);
                //spreadsheet?.goTo(getCellAddress(rIndex, updatedMessageIndex));
            }
        } catch (error) {
            console.error("Error : ", error);
        }
    }
    var nHTML = '';
    spreadsheet?.hideSpinner();
    if (errorRows.length > 0) {
      errorRows.forEach(function(item) {
        nHTML += '<li>' + item + '</li>';
      });
    
      document.getElementById("alertdialog")!.innerHTML = '<ul>' + nHTML + '</ul>'
      alertErrorDialog?.show();
    }
}

async function updateData(memoryData: any, spreadsheet: any, alertErrorDialog: any) {
    //const spreadsheet = SSObj.current;
    //if (!spreadsheet) return;
    //spreadsheet.showSpinner();
    const sheetName = spreadsheet.getActiveSheet().name;
    //const memoryData = sheetMemoryData.find(x => x.SheetName === sheetName)?.MemData;

    for (const rowIndex of modifiedRows) {
      const item = memoryData && memoryData[rowIndex - 1];
      const objTypeId = item && item["Object Type"];
      const objId = item["ID"];
      let data = await GetLatestProperties(objTypeId, objId);
      if (!data) {
        updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Updated Message"), "Object Not Found");
       continue;
      }
      if (data.Status === 404 || data.Status === 500) {
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Updated Message"), "Object Not Found");
          continue;
      }

     // if (checkForMandatoryValues(data, sheetName)) {
       // console.log('wwwww')
         // updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Updated Message"), errorRows.join("\n"));
        //  continue;
     // }

      data?.forEach((element: any) => {
          const item1 = window.ExistingPropIds?.find(x => x.Id === element.PropertyDef && x.SheetName === sheetName);
          if (item1 && window.ColumnHeaders?.indexOf(item1.Name) !== -1) {
              const cell = getCell(rowIndex, window.ColumnHeaders?.indexOf(item1.Name), spreadsheet.getActiveSheet()!);
              const cellValue = spreadsheet.getDisplayText(cell);
              handleCellUpdate(element, cellValue, item1);
          }
      });
      data = data.filter((element: any) => element.PropertyDef !== 100);

      const updateData = await UpdateObject(objTypeId, objId, data);

      if (!updateData.Status) {
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Modified"), "False");
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Update Status"), "True");
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Updated Message"), "Updated Successfully");
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("GUID"), updateData.ObjectGUID);
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("ID"), updateData.ObjVer.ID);
          //reloadDataInMemory(rowIndex);
      } else {
          errorRows.push("Error in record no." + rowIndex + " : " + updateData.Message)
          updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Updated Message"), updateData.Message);
      }
      //spreadsheet.goTo(getCellAddress(rowIndex, window.ColumnHeaders?.indexOf("Updated Message")));
  }
  var nHTML = ''; 
  spreadsheet.hideSpinner();
  if (errorRows.length > 0) {
    errorRows.forEach(function(item) {
        nHTML += '<li>' + item + '</li>';
      });
    
      document.getElementById("alertdialog")!.innerHTML = '<ul>' + nHTML + '</ul>'
      alertErrorDialog?.show();
  }
}
  //Update Data
  const handleCellUpdate = (element: any, cellValue: any, item1: any) => {
    if (element.PropertyDef !== item1.Id) return;

    const updateElementValue = (dataType: number) => {
        if (cellValue === null || cellValue === undefined) {
            element.TypedValue.DisplayValue = undefined;
            return;
        }

        if (cellValue !== element.TypedValue.DisplayValue) {
            element.TypedValue.DisplayValue = cellValue;
            element.TypedValue.Value = cellValue;
        }
    };

    switch (element.TypedValue.DataType) {
        case 1:
        case 2:
        case 5:
        case 7:
        case 13:
            updateElementValue(element.TypedValue.DataType);
            break;

        case 9:
            const singleSelectProps = window.SingleSelectProperties?.find(x => x.Id === item1.Id);
            if (singleSelectProps) {
                const items = singleSelectProps.DataArray;
                const clsId = singleSelectProps.ClsId;
                const lkupId = window.LookupIds?.find(x => x.clsId === clsId)?.lkupId;
                if (items) {
                    const item = clsId === -1
                        ? items.find(x => x.Name === cellValue)?.ID
                        : lkupId
                            ? items.find(x => x.LookupId === cellValue)?.ObjVer.ID
                            : items.find(x => x.Title === cellValue)?.ObjVer.ID;

                    element.TypedValue.Lookup = item === undefined
                        ? undefined
                        : { Item: item };
                }
            }
            break;

        case 10:
            const multiSelectProps = window.MultiSelectProperties?.find(x => x.Id === item1.Id);
            if (multiSelectProps) {
                const items = multiSelectProps.DataArray;
                const clsId = multiSelectProps.ClsId;
                const lkupId = window.LookupIds?.find(x => x.clsId === clsId)?.lkupId;

                if (items) {
                    if (cellValue) {
                        const lookups = cellValue.split(";").map(value => {
                            const item = clsId === -1
                                ? items.find(x => x.Name === value.trim())?.ID
                                : lkupId
                                    ? items.find(x => x.LookupId === value.trim())?.ObjVer.ID
                                    : items.find(x => x.Title === value.trim())?.ObjVer.ID;

                            return item === undefined
                                ? null
                                : { Item: item, Version: -1 };
                        }).filter(Boolean);
                        element.TypedValue.Lookups = lookups;
                    } else {
                        element.TypedValue.Lookups = undefined;
                    }
                }
            }
            break;

        case 8:
            if (cellValue === null || cellValue === undefined) {
                element.TypedValue.DisplayValue = undefined;
            } else {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue === "True";
            }
            break;
    }
}


// Triggers before going to the editing mode.
export const onCellEdit = (args, editDialog, spreadsheet: any) => {
  let sheetName = spreadsheet?.getActiveSheet().name;
  window.ExistingPropIds?.filter(x => x.SheetName == sheetName)?.forEach((prop) => {
    if (prop.AutomaticValueType != 0 && window.ColumnHeaders?.indexOf(prop.Name) !== -1) {
      var index = window.ColumnHeaders?.indexOf(prop.Name);
      let colHeaderText = getColumnHeaderText(index + 1);
      let tmp = args.address.split("!")[1];
      if (tmp.startsWith(colHeaderText)) {
        args.cancel = true;
        editDialog?.show();
      }
    }
  });
  window.ReorderColumnHeaders?.filter(x => x.SheetName == sheetName)?.forEach((element) => {
    if (element.IsAdmin == true && window.ColumnHeaders?.indexOf(element.Title) !== -1) {
      var index = window.ColumnHeaders?.indexOf(element.Title);
      let colHeaderText = getColumnHeaderText(index + 1);
      let tmp = args.address.split("!")[1];
      if (tmp.startsWith(colHeaderText)) {
        args.cancel = true;
        editDialog?.show();
      }
    }
  });
}

export const onCellEditing = (args: CellEditEventArgs, spreadsheet: any,  propDataType: any): void => {
  let sheetName = spreadsheet?.getActiveSheet().name;
  let memoryData = window.SheetMemoryData?.find(
    (x) => x.SheetName === sheetName
  )?.MemData;
  if (
    propDataType === 1 ||
    propDataType === 13 ||
    propDataType === 2 ||
    propDataType === 11
  ) {
    if (window.ColumnHeaders?.indexOf("Modified") !== -1)
      spreadsheet?.updateCell(
        { value: "True" },
        getCellAddress(currentRow, window.ColumnHeaders?.indexOf("Modified"))
      );
    let item = memoryData && memoryData[currentRow - 1];
    if (item !== undefined) item["Modified"] = "True";
  }
};

function checkForMandatoryValues(data: any, sheetName: any) {
  var isDirty: boolean = false;
  //check for value in mandatory property
  data?.forEach((element: any) => {
    var item = window.ExistingPropIds?.find((x) => x.Id == element.PropertyDef && x.SheetName == sheetName);
    if (item !== undefined && item !== null) {
      if (item.Required && (element.TypedValue.DisplayValue == "" || element.TypedValue.DisplayValue == null || element.TypedValue.DisplayValue == undefined)) {
        isDirty = true;
        errorRows.push(item.Name + ' is mandatory. Please enter a value.');
      }
    }      
  });
  return isDirty;
}


export async function saveInMFiles(spreadsheet: any): Promise<any> {
 // IsFileSave = true;
  let sheetName = spreadsheet?.getActiveSheet().name;
  spreadsheet?.showSpinner();
  let jsonData: any;
  var pdf = {
    fitSheetOnOnePage: false,
    orientation: "Portrait",
  };
  let docId = sessionStorage.getItem("docId");
  let data1 = await CheckOutObject(docId);
  if (data1 === undefined) {
    spreadsheet?.hideSpinner();
    return;
  }
  if (data1.Status == 500) {
    spreadsheet?.hideSpinner();
    //dialogInstance16.show();
    return data1;
  }
  await spreadsheet?.saveAsJson().then(async(response) => {
    jsonData = response;
    let arr = jsonData.jsonObject.Workbook.sheets[0].rows;
    window.RequiredAdminColumns?.forEach((col) => {
      if (window.ColumnHeaders?.indexOf(col) === -1) {
        arr[0].cells.push({
          value: col,
          style: ColumnHeaderStyle,
        });
      }
    });
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    memoryData?.forEach((element, index) => {
      window.RequiredAdminColumns?.forEach((col) => {
        if (window.ColumnHeaders?.indexOf(col) === -1) {
          arr[index + 1].cells.push({
            value: element[col],
            style: AdminColumnStyle,
          });
        }
      });
    });
    let formData: FormData = new FormData();
    formData.append("JSONData", JSON.stringify(jsonData.jsonObject.Workbook));
    formData.append("fileName", "Sample1");
    formData.append("saveType", "Xlsx");
    formData.append("versionType", "Xlsx");
    formData.append("fileContentType", "Xlsx");
    formData.append("pdfLayoutSettings", JSON.stringify(pdf));
   // IsFileSave = false;
    let res = await fetch(SERVER_URL + "api/provider/saveJson", {
      method: "POST",
      body: formData,
    });
    let data = await res.blob();
    let result = await UpdateViewReport(docId, data1?.ObjVer?.ID, data1?.Files[0]?.ID, data);
    return result;    
  });
  spreadsheet?.hideSpinner();
}

export function handleCreateNewObject (spreadsheet: any){
  //const spreadsheet: any = SSObj.current;
 // spreadsheet?.freezePanes(1, 0, 0);
  const sheet = spreadsheet.getActiveSheet();
  const rowCount = sheet.usedRange.rowIndex;

  // Loop through the rows to find the first empty row
  let lastEmptyRow = rowCount + 1; // Start with the next row after the last used row
  for (let i = rowCount; i >= 0; i--) {
    const row = sheet.rows[i];
    const isEmpty = row?.cells.every(cell => !cell.value);
    if (isEmpty) {
      lastEmptyRow = i;
    } else {
      break; // Exit loop when a non-empty row is found
    }
  }
  // Scroll to the last empty row
  spreadsheet?.goTo(`${lastEmptyRow + 1}`);
  addRow(lastEmptyRow + 1, spreadsheet);
}


 export async function selectHeader(sheetName: any, dropDownListVaultObject1: any, dropDownColHeaderObject: any, colHeaderDialog: any, colName: any){
    let list1 = { text: "Name", value: "ID" };
    //if (window.IsMFilesConnected === false) {
    if (sessionStorage.getItem("IsMFilesConnected") === "false"){
      return;
    }
    let data = await GetObjectTypes();
    dropDownListVaultObject1!.fields = list1;
    dropDownListVaultObject1!.dataSource = data;
    if (
      adminColData.find((x) => x.SheetName === sheetName) !==
      undefined
    ) {
      var tmp1 = adminColData.find(
        (x) => x.SheetName === sheetName
      );
      var tmp = dropDownListVaultObject1.dataSource.find(x => x.ID == tmp1?.ObjType);  //get the Name corresponding to the ID
      if (dropDownListVaultObject1!.text != tmp?.Name){
        dropDownListVaultObject1!.value = tmp1?.ObjType;
        dropDownListVaultObject1!.text = tmp?.Name;
      }
    }
    // if (memoryData === undefined) {
    //   //sheet not yet transformed and class not yet defined
    // } else {
    //   var properties: any[] = [];
    //   var item = memoryData && memoryData[0];
    //   if (item === undefined)
    //   {
    //     colHeaderDialog.show();
    //     return;
    //   }
    //   let clsId = item && item["Object Class"];
    //   if (clsId == -1){
    //     colHeaderDialog.show();
    //     return;
    //   } 
    //   let data = await GetClassProperties(clsId);
    //   let totalPropCount = data?.AssociatedPropertyDefs?.length;
    //   let ind = 0;
    //   data?.AssociatedPropertyDefs.forEach(async (element: any) => {
    //     ind++;
    //     let prop = await GetPropertyDetails(element.PropertyDef);
    //     if (properties.find((x) => x.Id === prop.ID) == null) {
    //       properties.push({ Name: prop.Name, Id: prop.ID });
    //     }
    //     if (ind === totalPropCount) {
    //       properties?.sort(function (a, b) {
    //         return compareStrings(a.Name, b.Name);
    //       });
    //     }
    //   });

    //   dropDownColHeaderObject.dataSource = properties;
    //   dropDownColHeaderObject.fields = { text: "Name", value: "Id" };
    //   dropDownColHeaderObject.text = colName;
    // }
    colHeaderDialog.show();
 }

 function dateTimeReviver(key: string, value: any) {
  var a;
  if (typeof value === 'string') {
      a = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(value)
      if (a && new Date(value).toString() !== "Invalid Date") {
          return formatDate(new Date(value));
      }
  }
  return value;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate()),
      year = d.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [month, day, year].join('/');
}

  //merge the external loaded file and appends the sheet to the view maven
  export async function fileMerge(args: any, spreadsheet: any, inputOpenFileRef: any) {
    var file = inputOpenFileRef.current.files[0];
    inputOpenFileRef.current.value = null;
    issheetRepeated = false;
    var menuItems: any[] = [];
    let sheetCount = spreadsheet?.sheets.length!;
    spreadsheet?.showSpinner();
    var formData = new FormData();
    formData.append("file", file);
    try{
    fetch(SERVER_URL + "api/provider/insertRecord", {
      method: "POST",
      body: formData,
    }).then((response) => {
      response?.json().then(async(data) => {
        var sheetName;
        var j = sheetCount;
        data?.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            issheetRepeated = false;
            if (spreadsheet?.sheets.find((x) => x.name === sheetName)) {
              issheetRepeated = true;
            }
            menuItems.push({
              text: sheetName,
              iconCss: "e-xlsx e-icons",
              repeated: issheetRepeated,
            });
          }
        });        
        data?.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            sheetName.trim();            
            return;
          }
          if (menuItems.find((x) => x.repeated === true && x.text === sheetName)) {           
            var newSheetName = renameSheetTab(spreadsheet, sheetName);
            sheetName = newSheetName;
          }
          var data = JSON.parse(temp, dateTimeReviver);
          spreadsheet?.insertSheet([
            {
              index: j,
              name: sheetName,
             // ranges: [{ dataSource: data }],
            },
          ]);
          spreadsheet?.updateRange({ dataSource: data}, j);
          //Apply the freeze pane.
          spreadsheet?.freezePanes(1, 0, j);
          if (data.length > 0) {
            try{
            var rowIndex = data.length + 1;
            var keyCount = Object.keys(data[0]).length;
            let colHeaderText = getColumnHeaderText(keyCount);
            let columnRange: string =
              getColumnHeaderText(1) + ":" + colHeaderText;
            var tmp = sheetName + "!" + columnRange;
            spreadsheet?.setColumnsWidth(120, [tmp]);
            //set font for header
            let columnRange1: string =
              getColumnHeaderText(1) + "1:" + colHeaderText + "1";
            var tmp1 = sheetName + "!" + columnRange1;

            let columnRange2: string =
              getColumnHeaderText(1) + "2:" + colHeaderText + rowIndex;
            var tmp2 = sheetName + "!" + columnRange2;

            spreadsheet?.cellFormat(
                ColumnHeaderStyle,
                tmp1
              );
               spreadsheet?.cellFormat(
                 ColumnStyle,
                 tmp2
               );
            
            //spreadsheet?.freezePanes(1, 0, j);
          }catch(error){
            spreadsheet?.hideSpinner();
          }
          }
          j++;
        });
        spreadsheet?.hideSpinner();
      });
    });
  }catch(error){
    spreadsheet?.hideSpinner();
  }
  }

  export async function reloadData(spreadsheet: any, objectId: any, guid: any, rIndex: any) {
    let objId, objTypeId, objTypeName, clsId, clsName;
    let sheetName = spreadsheet?.getActiveSheet().name;
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    let rowIndex = rIndex;
    let obj1 = memoryData && memoryData[rIndex - 1];
    objId = objectId;
    if (obj1 !== undefined) {
      objTypeId = obj1["Object Type"];
      objTypeName = obj1["Object Name"];
      clsId = obj1["Object Class"];
      clsName = obj1["Class Name"];
    }
    let data = await GetLatestProperties(objTypeId, objId);
    
    data?.forEach(function (element, i) {
      let propName = window.ExistingPropIds?.filter(x => x.SheetName == sheetName)?.find(
        (t) => t.Id === element.PropertyDef
      )?.Name;
      
      let propValue = element.TypedValue?.DisplayValue;
      let colIndex = window.ColumnHeaders?.indexOf(propName);
      if (colIndex !== -1 && propName !== undefined) {
        spreadsheet!.updateCell(
          {
            value: propValue,
            style: ColumnStyle,
          },
          sheetName +
          "!" +
          getCellAddress(rowIndex, window.ColumnHeaders?.indexOf(propName))
        );
      }
      if (propName !== undefined)
        obj1[propName] = propValue;
    });
    obj1["ID"] = objId?.toString();
    obj1["Object Type"] = objTypeId?.toString();
    obj1["Object Class"] = clsId?.toString();
    obj1["Modified"] = "FALSE";
    obj1["Update Status"] = "FALSE";
    obj1["Updated Message"] = "";
    obj1["GUID"] = guid;
    obj1["Class Name"] = clsName;
    obj1["Object Name"] = objTypeName;
    if (window.ColumnHeaders?.indexOf("ID") !== -1)
      spreadsheet?.updateCell(
        { value: objId, style: ColumnStyle },
        getCellAddress(rowIndex, window.ColumnHeaders?.indexOf("ID"))
      );
    if (window.ColumnHeaders?.indexOf("DesktopView") == -1) {
      return;
    }
    //update the edit link
    let data1 = await GetObjectVersion(objTypeId, objId);
    let latestVersion = data1.ObjVer.Version;
    if (window.ColumnHeaders?.indexOf("DesktopView") !== -1) {
      let editLink = getCell(
        rowIndex,
        window.ColumnHeaders?.indexOf("DesktopView"),
        spreadsheet!.getActiveSheet()
      ).value as string;
      if (editLink != undefined) {
        let result = editLink.indexOf("?");
        let string1 = editLink.substr(result - 1, 2);
        let updatedLink = editLink.replace(
          string1,
          latestVersion.toString() + "?"
        );
        spreadsheet?.updateCell(
          { value: updatedLink },
          getCellAddress(rowIndex, window.ColumnHeaders?.indexOf("DesktopView"))
        );
      }
    }
  }

  export function setCellValueBL(spreadsheet: any, value: any, text: any){
    let sheetName = spreadsheet?.getActiveSheet().name;
    let currentValue = getCell(
      currentRow,
      currentCol,
      spreadsheet?.getActiveSheet()!
    )?.value;
    if (currentValue != value) {
      spreadsheet?.updateCell(
        {
          value: text,
          //index: value,
          style: ColumnStyle,
        },
        getCellAddress(currentRow, currentCol)
      ); //  to specify the cell value, range
    }

    if (window.ColumnHeaders?.indexOf("Modified") !== -1)
      spreadsheet?.updateCell(
        { value: "True" },
        getCellAddress(currentRow, window.ColumnHeaders?.indexOf("Modified"))
      ); //  to specify the cell value, range

    //set Modified flag in In-Memory data
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    let item = memoryData && memoryData[currentRow - 1];
    if (item !== undefined) item["Modified"] = "True";
  }

//   //Menu Implementations
// export function onFileMenuBeforeOpen(args: any, spreadsheet: any){
//   console.log(args.items.length);
//   if (args.items.length === 4) {
//     spreadsheet?.addFileMenuItems(
//       [{ text: "Load New File", iconCss: "e-open e-icons" }],
//       "Open",
//       false
//     );
//   }
//  // spreadsheet?.hideFileMenuItems(["Open"]);
// };

export function onFileMenuItemSelect(args: any, inputOpenFileRef: any) {
  if (args.item.text === "Load New File") { //import external excel file to view maven
    inputOpenFileRef.current.click();
  }
}

export const onContextMenuItemSelect = (args: any, spreadsheet: any) => {
  let objId, guid, objTypeId;
  let sheet = spreadsheet?.getActiveSheet();
  let sheetName = spreadsheet?.getActiveSheet().name;
  let memoryData = window.SheetMemoryData?.find(
    (x) => x.SheetName === sheetName
  )?.MemData;
  let rangeAddress = sheet?.activeCell as string;
  let rowIndex = Number(rangeAddress.replace(/[^0-9]/gi, "")); //row

  let item = memoryData && memoryData[rowIndex - 2];
  if (item !== undefined) {
    objId = item["ID"];
    guid = item["GUID"];
    objTypeId = item["Object Type"];
    if (args.item.text === "Edit In M-Files" && guid !== undefined) {
      let tempvaultId = vaultId.replace("{", "").replace("}", "");
      guid = guid!.replace("{", "").replace("}", "");
      var editLink =
        "m-files://edit/" + tempvaultId + "/" + objTypeId + "-" + objId;
      window.location.href = editLink as string;
    }
    if (args.item.text === "Reload Record") {
      reloadData(spreadsheet, objId, guid, rowIndex - 1);
    }
    if (args.item.text === "Insert Record") {
      addRow(rowIndex, spreadsheet);
    }
  }
};

export function addRow(rowIndex: number, spreadsheet: any) {
  let objTypeId, objTypeName, clsId, clsName;
  if (!spreadsheet) return;
  const columnIndices = {
      objectType: window.ColumnHeaders?.indexOf("Object Type"),
      objectName: window.ColumnHeaders?.indexOf("Object Name"),
      objectClass: window.ColumnHeaders?.indexOf("Object Class"),
      className: window.ColumnHeaders?.indexOf("Class Name"),
      modified: window.ColumnHeaders?.indexOf("Modified"),
      updateStatus: window.ColumnHeaders?.indexOf("Update Status"),
      id: window.ColumnHeaders?.indexOf("ID"),
  };

  const updateCellIfExists = (column: number, value: any) => {
      if (column !== -1) {
          spreadsheet!.updateCell(
              { value: value?.toString() },
              getCellAddress(rowIndex - 1, column)
          );
      }
  };
  const sheetName = spreadsheet.getActiveSheet().name;
  const memoryData = window.SheetMemoryData?.find(x => x.SheetName === sheetName)?.MemData || [];
  let item = memoryData && memoryData[rowIndex - 3];
  if (item !== undefined) {
    objTypeId = item["Object Type"];
    objTypeName = item["Object Name"];
    clsId = item["Object Class"];
    clsName = item["Class Name"]
  }
  
  updateCellIfExists(columnIndices.objectType, objTypeId);
  updateCellIfExists(columnIndices.objectName, objTypeName);
  updateCellIfExists(columnIndices.objectClass, clsId);
  updateCellIfExists(columnIndices.className, clsName);
  updateCellIfExists(columnIndices.modified, "FALSE");
  updateCellIfExists(columnIndices.updateStatus, "FALSE");
  updateCellIfExists(columnIndices.id, "-1");

  const colHeaderText = getColumnHeaderText(window.ColumnHeaders?.length);
  spreadsheet.cellFormat(
      ColumnStyle, 
      `B${rowIndex}:${colHeaderText}${rowIndex}`
  );

  markObligatoryProperties(true, false, spreadsheet);

  const newObj = {
      ID: "-1",
      "Object Class": clsId,
      "Class Name": clsName,
      "Object Type": objTypeId,
      "Object Name": objTypeName
  };

  memoryData.splice(rowIndex - 2, 0, newObj);
}



export async function onObjectTypeChange(args: any, spreadsheet: any, 
  dropDownListVaultClass: any, dropDownListVaultClass1: any, adminColData: any, isTransform: boolean) {
  if (args.itemData === null) return;
  let sheetName = spreadsheet?.getActiveSheet().name;
  var objTypeId = args.itemData.ID;
  let data = await GetClasses(objTypeId);
  if (adminColData.find((x) => x.SheetName === sheetName) !== undefined) {
    var tmp = adminColData.find((x) => x.SheetName === sheetName);
  }
 if (isTransform){
  if (dropDownListVaultClass !== undefined){
     dropDownListVaultClass!.dataSource = data;    
     var cls = dropDownListVaultClass!.dataSource.find(x => x.ID == tmp?.Cls);
     if (cls !== undefined){
      dropDownListVaultClass!.value = tmp?.Cls; 
      dropDownListVaultClass!.text = cls?.Name;
     }    
  }
 }
 else{ //column header dialog
  if (dropDownListVaultClass1 !== undefined){
    dropDownListVaultClass1!.dataSource = data;
    var cls = dropDownListVaultClass1!.dataSource.find(x => x.ID == tmp?.Cls);
    if (cls !== undefined){
       dropDownListVaultClass1!.value = tmp?.Cls; 
      dropDownListVaultClass1!.text = cls?.Name;
   }
  }
 }
};

export async function onClassChange(args: any, dropDownListUniqueProperty: any, dropDownColHeaderObject: any, colName: any) {
  var properties: any[] = [];
  var list1 = { text: "Name", value: "Id" };
  var clsId = args?.itemData?.ID;
  if (clsId === undefined) return;
  IsLookupIdDefined(clsId);
  const data = await GetClassProperties(clsId);
  let totalPropCount = data?.AssociatedPropertyDefs?.length;
  let ind = 0;
  data?.AssociatedPropertyDefs.forEach(async (element: any) => {
    ind++;
    const prop = await GetPropertyDetails(element.PropertyDef);
    if (properties.find((x) => x.Id === prop.ID) == null) {
      properties.push({ Name: prop.Name, Id: prop.ID });
    }
    if (ind === totalPropCount) {
      properties?.sort(function (a, b) {
        return compareStrings(a.Name, b.Name);
      });
    }
  });
  if (dropDownListUniqueProperty !== undefined) dropDownListUniqueProperty.dataSource = properties;
  if (dropDownListUniqueProperty !== undefined) dropDownListUniqueProperty.fields = list1;
  if (dropDownColHeaderObject !== undefined) dropDownColHeaderObject.dataSource = properties;
  if (dropDownColHeaderObject !== undefined) dropDownColHeaderObject.fields = list1;
  if (dropDownColHeaderObject !== undefined) dropDownColHeaderObject.text = colName;
}



export async function refreshObjects(selectedpropId: any, dropDownListObject: any, multiSelectListObject: any, multiSelectData?: any) {
  var clsId = -1;
  var isMultiplClass = false;
  var isAutomatic = false;
  var propobjTypeId = -1;

  let propName = window.ExistingPropIds?.find(
    (t) => t.Id === selectedpropId
  )?.Name;
  let propDataType = window.ExistingPropIds?.find(
    (t) => t.Id === selectedpropId
  )?.DataType;
  if (propDataType === 9) {
    clsId = window.SingleSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.ClsId;
    propobjTypeId = window.SingleSelectProperties?.find((x) => x.Id == selectedpropId)?.ObjTypeId;
    isMultiplClass = window.SingleSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.IsMultipleClass;
    isAutomatic = window.SingleSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.IsAutomatic;
  }
  else if (propDataType === 10) {
    clsId = window.MultiSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.ClsId;
    propobjTypeId = window.MultiSelectProperties?.find((x) => x.Id == selectedpropId)?.ObjTypeId;
    isMultiplClass = window.MultiSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.IsMultipleClass;
    isAutomatic = window.MultiSelectProperties?.find(
      (x) => x.Id === selectedpropId
    )?.IsAutomatic;
  }

  await GetData(
    propName,
    selectedpropId,
    clsId,
    propobjTypeId,
    propDataType,
    -1,
    false,//loadall
    isMultiplClass,
    true,//refresh
    isAutomatic
  );
  if (propDataType === 9) {
    dropDownListObject.dataSource = window.SingleSelectProperties?.find((x) => x.Id == selectedpropId).DataArray;
    dropDownListObject.refresh();
  }
  else if (propDataType === 10) {
    multiSelectListObject.dataSource = window.MultiSelectProperties?.find((x) => x.Id == selectedpropId).DataArray;
    multiSelectData = window.MultiSelectProperties?.find((x) => x.Id == selectedpropId).DataArray;
    multiSelectListObject.refresh();
  }
}

export function loadDataInDropDown(propName: any, propId: any, propDataType: any, loadAll: boolean, 
  dropDownListObject: any, multiSelectListObject: any, dialogInstance: any, dialogInstance1: any, cellValue: any) {
  var list, items, clsId, ismultipleClass, tmpclsId, propobjTypeId;
  if (propDataType == 9) {
    items = window.SingleSelectProperties?.find((x) => x.Id == propId)?.DataArray;
    clsId = window.SingleSelectProperties?.find((x) => x.Id == propId)?.ClsId;
    ismultipleClass = window.SingleSelectProperties?.find((x) => x.Id == propId)?.IsMultipleClass;
  } else if (propDataType == 10) {
    items = window.MultiSelectProperties?.find((x) => x.Id == propId)?.DataArray;
    clsId = window.MultiSelectProperties?.find((x) => x.Id == propId)?.ClsId;
    ismultipleClass = window.MultiSelectProperties?.find((x) => x.Id == propId)?.IsMultipleClass;
  }

  if (ismultipleClass && clsId !== undefined && !(Number.isInteger(clsId))) {
    var temp = clsId?.split(",");
    tmpclsId = temp[0];
  }
  else
    tmpclsId = clsId;
  var lkupId = window.LookupIds?.find((x) => x.clsId === Number(tmpclsId))?.lkupId;

  if (clsId == -1) {//valuelist
    if (propDataType == 9) {
      list = { text: "Name", value: "ID" };
    }
    if (propDataType == 10) {
      list = { text: "Name", value: "Name" };
    }
  } else {
    if (lkupId == undefined) {
      if (propDataType == 9) {
        list = { text: "Title", value: "ObjVer.ID" };
      }
      if (propDataType == 10) {
        list = { text: "Title", value: "Title" };
      }
    } else {
      if (propDataType == 9) {
        list = { text: "Title", value: "LookupId" };
      }
      if (propDataType == 10) {
        list = { text: "Title", value: "LookupId" };
      }
    }
  }

  if (propDataType === 9) {
    dropDownListObject.fields = list;
    dropDownListObject.dataSource = items;
    dropDownListObject.text = cellValue;
    dialogInstance.header = propName + " List";
    if (loadAll) {
      dialogInstance.show();
    }
  } else if (propDataType === 10) {
    multiSelectListObject.fields = list;
    multiSelectListObject.dataSource = items;
    if (cellValue !== "")
    multiSelectListObject.value = cellValue.split(';').map(string => string.trim());
  else
    multiSelectListObject.value = cellValue;
    dialogInstance1.header = propName + " List";
    if (loadAll) {
      dialogInstance1.show();
    }
  }
}

export function enableToolbarItems(spreadsheet: any, colHeaders: any) {
  if (sessionStorage.getItem("IsMFilesConnected") == "true"){
    if (colHeaders !== undefined && colHeaders.length > 0) {
      var index = colHeaders.findIndex((x) => x.Title == "Object Class") && colHeaders.findIndex((x) => x.Title == "Object Type");
      if (index !== -1)//Object Type is defined for the sheet
      {
        spreadsheet?.enableToolbarItems("M-Files", [0], true);
        spreadsheet?.enableToolbarItems("M-Files", [1], true);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);  
        spreadsheet?.enableToolbarItems("M-Files", [3], true);  
        spreadsheet?.enableToolbarItems("M-Files", [4], true); 
      }
      else
      {
        spreadsheet?.enableToolbarItems("M-Files", [0], false);
        spreadsheet?.enableToolbarItems("M-Files", [1], false);
        spreadsheet?.enableToolbarItems("M-Files", [2], true);  
        spreadsheet?.enableToolbarItems("M-Files", [3], true);  
        spreadsheet?.enableToolbarItems("M-Files", [4], false); 
      }
    }
  }
  else{
    spreadsheet?.enableToolbarItems("M-Files", [0], false);
    spreadsheet?.enableToolbarItems("M-Files", [1], false);
    spreadsheet?.enableToolbarItems("M-Files", [2], false);  
    spreadsheet?.enableToolbarItems("M-Files", [3], true);  
    spreadsheet?.enableToolbarItems("M-Files", [4], false);  
  } 
}

export async function transformToView(spreadsheet: any,  dropDownListVaultObject: any, dropDownListUniqueProperty: any, transformDialog: any) {
  let sheetName = spreadsheet?.getActiveSheet().name;
  if (IsColumnExist(spreadsheet, "Object Type")){
    spreadsheet?.enableToolbarItems("M-Files", [2], false);
    return;
  } 
  spreadsheet?.showSpinner();
  let data = await GetObjectTypes();
  let list1 = { text: "Name", value: "ID" };
  dropDownListVaultObject.fields = list1;
  dropDownListVaultObject.dataSource = data;
  if (adminColData.find((x) => x.SheetName === sheetName) !== undefined) {
    var tmp = adminColData.find((x) => x.SheetName === sheetName);
    dropDownListVaultObject.value = tmp?.ObjType;
  }
  //Code to identify unique identifier column for particular sheet..
  //If LookupId column is not present in the sheet
  var currentColHeaders = window.ReorderColumnHeaders?.filter(
    (x) => x.SheetName === sheetName
  );
  if (currentColHeaders?.find((x) => x.Title === "Lookup ID") === undefined) {
    dropDownListUniqueProperty!.enabled = true;
  } else dropDownListUniqueProperty!.enabled = false;

  spreadsheet?.hideSpinner();
  transformDialog?.show();
}

export async function transformSheet(spreadsheet: any, arrangeListbox: any, transformDialog: any, setSelectedAdmin: any, dropDownListVaultObject: any, dropDownListVaultClass: any, dropDownListUniqueProperty: any, loader: any) {
  transformDialog.hide();  
  let sheetName = spreadsheet?.getActiveSheet().name;
  if (IsColumnExist(spreadsheet, "Object Type")) return;
  //if (transformedSheet.includes(sheetName)) return;

  spreadsheet?.showSpinner();  
  const {
      value: objTypeId, text: objTypeName
  } = dropDownListVaultObject;
  
  const {
      value: clsId, text: clsName
  } = dropDownListVaultClass;
  
  const {
      value: uniquePropertyId, text: uniquePropertyName
  } = dropDownListUniqueProperty;
  if (dropDownListVaultObject.value == null || dropDownListVaultClass.value == null) {
    spreadsheet?.hideSpinner();
    return;
  }

  const existingEntry = adminColData.find(x => x.SheetName === sheetName);
  
  if (existingEntry) {
      existingEntry.Cls = dropDownListVaultClass.text;
      existingEntry.ObjType = dropDownListVaultObject.text;
  } else {
      adminColData.push({
          Cls: dropDownListVaultClass.value,
          ObjType: dropDownListVaultObject.value,
          SheetName: sheetName,
      });
  }  
  const activeSheet = spreadsheet?.getActiveSheet();
  const usedRange = activeSheet?.usedRange;
  const usedRowIdx = usedRange?.rowIndex || 0;
  let usedColIdx = usedRange?.colIndex || 0;
  arrangeListbox.dataSource = [];
  let props: any[] = [];
  let existingIds: any[] = [];
  var data;
  await addAdminColumnHeaders(spreadsheet);
  await spreadsheet?.freezePanes(1,0);
  await addObligatoryColumns(spreadsheet, dropDownListVaultClass.value, props);
  let lastEmptyRow = getLastEmptyRow(spreadsheet) - 1;
  
 // await adjustColumnWidths(spreadsheet);
  let sheetIndex = getSheetIndex(spreadsheet, sheetName);
  if (sheetIndex !== undefined) {
      await getObjectPropertiesBL(spreadsheet, sheetIndex, clsId);
  }
  lastEmptyRow = getLastEmptyRow(spreadsheet) - 1;
  if(lastEmptyRow == 0){
    //load objects  
    await GetClassObjects(dropDownListVaultObject.value, dropDownListVaultClass.value).then(async(obj) => {
      obj?.Items?.sort(function (a, b) {
        return compareNumbers(a.ObjVer.ID, b.ObjVer.ID);
      });
      data = obj;
     // console.log(data);
      await FillClassData(spreadsheet, dropDownListVaultObject.value, props, data, existingIds, lastEmptyRow);
      lastEmptyRow = await getLastEmptyRow(spreadsheet) - 1;
      await updateSheetCells(
        spreadsheet,
        objTypeId,
        objTypeName,
        clsId,
        clsName,
        lastEmptyRow,
        usedColIdx
      );
      spreadsheet?.hideSpinner() ;
    });
  }
  else if (lastEmptyRow > 0){   
    //add values for Guid, Id to the rows
    await processRows({  
        spreadsheet,
        objTypeId,
        clsId,
        uniquePropertyId, uniquePropertyName,
        sheetName,
        lastEmptyRow
    });
    //fill values for obligatory columns
    FillObligatoryColumns(spreadsheet, dropDownListVaultObject.value, props, lastEmptyRow);
    if (window.confirm("Do you want to load all objects from M-Files?") == true) {
      await GetClassObjects(dropDownListVaultObject.value, dropDownListVaultClass.value).then(async(obj) => {
        obj?.Items?.sort(function (a, b) {
          return compareNumbers(a.ObjVer.ID, b.ObjVer.ID);
        });
        data = obj;
      });
      for (let i = 1; i <= usedRowIdx; i++) {
        var id = getCell(i, window.ColumnHeaders?.indexOf("ID"), spreadsheet.getActiveSheet())?.value;
        existingIds.push({Id: id});
      }
      await FillClassData(spreadsheet, dropDownListVaultObject.value, props, data, existingIds, lastEmptyRow);
    }
    lastEmptyRow = await getLastEmptyRow(spreadsheet) - 1;
    await updateSheetCells(
      spreadsheet,
      objTypeId,
      objTypeName,
      clsId,
      clsName,
      lastEmptyRow,
      usedColIdx
    );
  } 
  
  setSelectedAdmin(true);
  await loadMemoryDataFromSheet(spreadsheet, sheetIndex!, false, false, loader);
  console.log(memoryData)
  transformedSheet.push(sheetName);
  let colHeaders = window.ReorderColumnHeaders?.filter(
    (x) => x.SheetName === sheetName
  )
  enableToolbarItems(spreadsheet, colHeaders);
  spreadsheet?.enableToolbarItems("M-Files", [2], false);
 
  spreadsheet?.hideSpinner(); 
  
}

async function FillClassData(spreadsheet, objTypeId, props, data, existingIds, number){
  for (const item of data?.Items) { 
    console.log(item);
    if (existingIds.length == 0 || existingIds.find(x => x.Id == item.ObjVer.ID) === undefined){
      spreadsheet?.updateCell(
        {
          value: item.ObjVer.ID,
          style: ColumnStyle
        },
        getCellAddress(number + 1, window.ColumnHeaders?.indexOf("ID"))
      );  
      spreadsheet?.updateCell(
        {
          value: item.ObjectGUID,
          style: ColumnStyle
        },
        getCellAddress(number + 1, window.ColumnHeaders?.indexOf("GUID"))
      );    
      let properties = await GetLatestProperties(objTypeId, item.ObjVer.ID);
      props.forEach(async(prop, index) => {
        var value = await properties.find((x) => x.PropertyDef == prop.Id)?.TypedValue?.DisplayValue;
        spreadsheet?.updateCell(
          {
            value: value,
            style: ColumnStyle
          },
          getCellAddress(number,  window.ColumnHeaders?.indexOf(prop.Name))
        );
      })
      number++;
    }
  }
 
}

async function FillObligatoryColumns(spreadsheet, objTypeId, props, lastEmptyRow:any) {
  for (let i = 1; i <= lastEmptyRow; i++) {
    var id = getCell(i, window.ColumnHeaders?.indexOf("ID"), spreadsheet.getActiveSheet())?.value;
    console.log(id);
    if (Number(id) === -1 || id === undefined)
      continue;
    let properties = await GetLatestProperties(objTypeId, id);
    console.log(properties);
    props.forEach(async(prop, index) => {
      var value = await properties.find((x) => x.PropertyDef == prop.Id)?.TypedValue?.DisplayValue;
      console.log(value)
      spreadsheet?.updateCell(
        {
          value: value,
          style: ColumnStyle
        },
        getCellAddress(i, window.ColumnHeaders?.indexOf(prop.Name))
      );
    })
  }
  
}

async function processRows({ spreadsheet, objTypeId, clsId, uniquePropertyId, uniquePropertyName,  sheetName, lastEmptyRow }) {
  if (lastEmptyRow < 1) lastEmptyRow = 1; //just add one row for recording the ObjectType, ObjectClass
  for (let i = 1; i <= lastEmptyRow; i++) {
    if (uniquePropertyId == null || uniquePropertyName == null)
    {
      await updateNewObject(spreadsheet, i);
      continue;
    }
  const lkupId = await getLookupId(spreadsheet, i, uniquePropertyName, sheetName);
  const data = await CheckObjectExists(objTypeId, clsId, uniquePropertyId, lkupId, sheetName);
  if (data?.Items?.length > 0) {
      await updateExistingObject(spreadsheet, i, data.Items[0]);
  } else {
      await updateNewObject(spreadsheet, i);
  }
}
}

async function getLookupId(spreadsheet, rowIndex, uniquePropertyName, sheetName) {
  if (window.ReorderColumnHeaders?.some(x => x.Title === "Lookup ID" && x.SheetName === sheetName)) {
      return getCell(rowIndex, window.ColumnHeaders?.indexOf("Lookup ID"), spreadsheet.getActiveSheet())?.value;
  }
  
  return getCell(rowIndex, window.ColumnHeaders?.indexOf(uniquePropertyName), spreadsheet.getActiveSheet())?.value;
}



export async function loadMemoryDataFromSheet(spreadsheet: any, sheetIndex: number, isSort: boolean, isLoad: boolean, loader: any, hiddenRows?: any) {
  if (!spreadsheet) return;
  const sheetName = spreadsheet.sheets[sheetIndex]?.name;
  if (!sheetName) return;
  const findSheetMemoryData = (sheetName: string) => window.SheetMemoryData?.find(x => x.SheetName === sheetName)?.MemData;

  if (isSort) {
      const existingData = findSheetMemoryData(sheetName);
      if (existingData) {
          memoryData = [...existingData];
          memoryData?.sort((a, b) => compareStrings(a.Name, b.Name));
      }
  }

  const existingData = findSheetMemoryData(sheetName);
  if (existingData) {
      memoryData = existingData;
      return;
  }
  const lastEmptyRow = getLastEmptyRow(spreadsheet) - 1;

  try {
    await spreadsheet?.saveAsJson().then(async(response: any) => {      
      //isSave = true;
      memoryData = [];
      memoryStyleData = [];
      colStyleWidth = [];
      wrapStyle = [];
      jsonData = response;
      let obj = JSON.parse(JSON.stringify(jsonData.jsonObject.Workbook));
      const sheet = obj.sheets[sheetIndex];
      const rows = sheet.rows;
      const cols = sheet.columns;
      const cells = rows[0]?.cells;
      // Process row data
      for (let i = 1; i <= lastEmptyRow; i++) {
          const row = rows[i];
          const obj = {};
          const styleObj = {};

          for (let j = 0; j < cells.length; j++) {
              const colHeader = rows[0].cells[j]?.value;
              if (colHeader === undefined) continue;
              if (row.cells[j]?.format !== undefined){ //Date format
                let cell = getCell(i, j, sheet);
                let cellValue = spreadsheet.getDisplayText(cell);
                obj[colHeader] = cellValue;
              }
              else
              {
                if (row.cells[j]?.value === undefined)
                    obj[colHeader] = "";
                else
                  obj[colHeader] = row.cells[j]?.value;
              }
              styleObj[colHeader] = row.cells[j]?.style;
          }

          memoryData.push(obj);
          memoryStyleData.push(styleObj);
      }
      // Process column width data
      const widthObj = {};
      const wrapObj = {};
      for (let j = 0; j < cells.length; j++) {
          const colHeader = rows[0].cells[j]?.value;
          if (colHeader === undefined) continue;

          widthObj[colHeader] = cols[j]?.width;
          wrapObj[colHeader] = rows[0].cells[j]?.wrap; 
      }
      colStyleWidth.push(widthObj);
      wrapStyle.push(wrapObj);
    

      // Store global object type and class info
      const firstItem = memoryData[0] || {};
      let objTypeId = firstItem["Object Type"];
      let objTypeName = firstItem["Object Name"];
      let clsId = firstItem["Object Class"];
      let clsName = firstItem["Class Name"];

      if (!adminColData.some(x => x.SheetName === sheetName)) {
          adminColData.push({
              Cls: clsId,
              ObjType: objTypeId,
              SheetName: spreadsheet.getActiveSheet().name,
          });
      }
      // Fetch latest properties for rows with valid IDs
       await Promise.all(memoryData.map(async (row) => {
          if (row["ID"] && row["ID"] !== "-1" && row["ID"] !== -1) {
              const data = await GetLatestProperties(row["Object Type"], row["ID"]);
              //console.log('data', data);
              if (data === undefined) return;
              if (data.Status === 404 || data.Status === 500 || data.Status === 400) return;

              data?.forEach((element: any) => {
                  const propName = window.ExistingPropIds?.find(t => t.Id === element.PropertyDef)?.Name;
                  const propValue = element.TypedValue?.DisplayValue;
                  if (propName) row[propName] = propValue;
              });
          }
      }));
      if (window.SheetMemoryData === undefined){
        window.SheetMemoryData = [];
      }
      if (window.StyleMemoryData === undefined){
        window.StyleMemoryData = [];
      }
      if (window.SheetMemoryData?.indexOf(sheetName) === -1)
         window.SheetMemoryData?.push({ SheetName: sheetName, MemData: memoryData });
      if (window.StyleMemoryData?.indexOf(sheetName) === -1)
          window.StyleMemoryData?.push({ SheetName: sheetName, MemData: memoryStyleData, ColWidth: colStyleWidth, Wrap: wrapStyle });

    });
  classList(loader, ["e-spin-hide"],["e-spin-show"]);

  } catch (error) {
      console.error("Error loading memory data:", error);
      classList(loader, ["e-spin-hide"],["e-spin-show"]);
  }
  if (isLoad) {
    hideAdminColumns(spreadsheet); // hide admin columns for the first time 
}
}

  