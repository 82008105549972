import { useMsal } from "@azure/msal-react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import loginImg from "../assets/images/login-image.png";
import "../styles/Login.scss";
import { loginRequest } from "../utils/authConfig";
import { useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";

const Login = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const [localUser, setLocalUser] = useState(
    localStorage.getItem("viewMavenUser")
  );

  useEffect(() => {
    if (localUser) {
      setLoading(true);
      handleLogin();
    }
  }, [localUser]);

  const handleLogin = () => {
    console.log(loginRequest);
    //window.IsMFilesConnected = false;
    sessionStorage.setItem("IsMFilesConnected", "false");
    // instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  return (
    <><NavigationBar/>
    {loading && (
        <div className="e-custom-spinner e-spin-show">
          <div className="e-ss-custom-loader"></div>
        </div>
      )}
      {!localUser && (
        <div className="login-container">
          <div className="login-form">
            <div className="login-logo">
              <h2 className="login-title">Welcome to View Maven</h2>
              <img src={loginImg} alt="login-img" className="login-img" />
            </div>

            <div className="login-button">
              <ButtonComponent
                cssClass="e-primary"
                className="login-btn"
                onClick={() => handleLogin()}
              >
                Login to View Maven
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
